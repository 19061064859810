import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'waitpage/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  
  { path: 'welcome', loadChildren: './welcome/welcome.module#WelcomePageModule' },
  { path: 'city', loadChildren: './account/city/city.module#CityPageModule' },
  { path: 'item', loadChildren: './item/item.module#ItemPageModule' },
  { path: 'option', loadChildren: './option/option.module#OptionPageModule' },
  { path: 'info', loadChildren: './info/info.module#InfoPageModule' },
  { path: 'cart', loadChildren: './cart/cart.module#CartPageModule' },
  { path: 'offer', loadChildren: './offer/offer.module#OfferPageModule' },
  { path: 'login', loadChildren: './account/login/login.module#LoginPageModule' },
  { path: 'chkphone', loadChildren: './account/chkphone/chkphone.module#ChkphonePageModule'},
  { path: 'verfycode', loadChildren: './account/verfycode/verfycode.module#VerfycodePageModule'},
  { path: 'signup', loadChildren: './account/signup/signup.module#SignupPageModule' },
  { path: 'forgot', loadChildren: './account/forgot/forgot.module#ForgotPageModule' },
  { path: 'address', loadChildren: './account/address/address.module#AddressPageModule' },
  { path: 'done', loadChildren: './done/done.module#DonePageModule' },
  { path: 'profile', loadChildren: './account/profile/profile.module#ProfilePageModule' },
  { path: 'order', loadChildren: './account/order/order.module#OrderPageModule' },
  { path: 'rate/:id/:type', loadChildren: './account/rate/rate.module#RatePageModule' },
  { path: 'about', loadChildren: './page/about/about.module#AboutPageModule' },
  { path: 'how', loadChildren: './page/how/how.module#HowPageModule' },
  { path: 'faq', loadChildren: './page/faq/faq.module#FaqPageModule' },
  { path: 'contact', loadChildren: './page/contact/contact.module#ContactPageModule' },
  { path: 'lang', loadChildren: './lang/lang.module#LangPageModule' },
  { path: 'categorys', loadChildren: './categorys/categorys.module#CategorysPageModule'},
  { path: 'locked', loadChildren: './locked/locked.module#LockedPageModule'},
  {
    path: 'setaddress',
    loadChildren: () => import('./account/address/setaddress/setaddress.module').then( m => m.SetaddressPageModule)
  },
  {
    path: 'option-pay',
    loadChildren: () => import('./account/option-pay/option-pay.module').then( m => m.OptionPayPageModule)
  },
  {
    path: 'form-card',
    loadChildren: () => import('./account/form-card/form-card.module').then( m => m.FormCardPageModule)
  },
  {
    path: 'info-fee',
    loadChildren: () => import('./cart/info-fee/info-fee.module').then( m => m.InfoFeePageModule)
  },
  {
    path: 'waitpage/:redirect',
    loadChildren: () => import('./waitpage/waitpage.module').then( m => m.WaitpagePageModule)
  },
  {
    path: 'filters',
    loadChildren: () => import('./filters/filters.module').then( m => m.FiltersPageModule)
  },
  {
    path: 'favorites',
    loadChildren: () => import('./account/favorites/favorites.module').then( m => m.FavoritesPageModule)
  },
  {
    path: 'rate-trip',
    loadChildren: () => import('./done/rate-trip/rate-trip.module').then( m => m.RateTripPageModule)
  },
  {
    path: 'verify-code',
    loadChildren: () => import('./account/login/verify-code/verify-code.module').then( m => m.VerifyCodePageModule)
  },
  {
    path: 'view-trip',
    loadChildren: () => import('./done/view-trip/view-trip.module').then( m => m.ViewTripPageModule)
  },
  {
    path: 'info-user',
    loadChildren: () => import('./cart/info-user/info-user.module').then( m => m.InfoUserPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
