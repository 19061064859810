import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-info-user',
  templateUrl: './info-user.page.html',
  styleUrls: ['./info-user.page.scss'],
})
export class InfoUserPage implements OnInit {

  user_name: string;
  user_email: string;
  user_phone: string;

  constructor(
    public modalController: ModalController
  ) { 
    this.user_name = localStorage.getItem('user_name');
    this.user_email = localStorage.getItem('user_email');
    this.user_phone = localStorage.getItem('user_phone');
  }

  ngOnInit() {
  }

  saveInfo(data)
  {
    this.modalController.dismiss(data,'saveInfo');
  }

}
