import { Component, Renderer2,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform,NavController,Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { ServerService } from './service/server.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  
  appType:number = 0;
  dir:string = "ltr";
  text:any;
  apiKey: any;
  public appPages:any = [];

  
  admin:any;
  user:any;
  constructor(
    public server : ServerService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public nav : NavController,
    private oneSignal: OneSignal,
    public events: Events,
    public renderer: Renderer2,
    @Inject(DOCUMENT) private _document
  ) {

    
    this.loadData();
    this.events.subscribe('admin', (type) => {  
      this.admin = type;
    });

    if(localStorage.getItem('admin'))
    {
      this.admin = JSON.parse(localStorage.getItem('admin'));
    }
    
    // Cargamos el menu lateral
    this.loadMenu();
    // Obtenemos actualizacion del menu
    this.events.subscribe('text', (text) => {
      this.text = text;
      this.appPages = [
        {
          title: text.home,
          url: '/home',
          icon: 'home'
        },
        {
          title: "Pedido activos",
          url: '/done',
          icon: 'rocket'
        }
      ];
    });


    this.initializeApp();

    this.events.subscribe('user_login', (id) => {
      this.subPush(id);
    });

    this.events.subscribe('user', (data) => {
      this.user = data;
    });
  }

  loadMenu()
  {
    this.appPages = [
      {
        title: "Inicio",
        url: '/home',
        icon: 'home'
      },
      {
        title: "Pedidos activos",
        url: '/done',
        icon: 'rocket'
      }
    ];
  }

  assginAppType(ty)
  {
    this.dir = ty == 0 ? "ltr" : "rtl";
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString("#ffffff");
      this.statusBar.styleDefault();
    });

  }

  subPush(id = 0)
  {
    this.oneSignal.startInit('0883c43e-0272-4c81-9e54-4649ab40d6fa', '942709391167');

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(() => {
     // do something when notification is received
    });

    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
  });
    
  if(localStorage.getItem('user_id') && localStorage.getItem('user_id') != 'null')
  {
      this.oneSignal.sendTags({user_id: localStorage.getItem('user_id')});
  }

  if(id > 0)
  {
      this.oneSignal.sendTags({user_id: id});
  }

  this.oneSignal.endInit();
  } 
  
  logout()
  {
    localStorage.setItem('user_id',null);

    this.nav.navigateForward('/welcome');
  }
    
  async loadData()
  {
    this.server.getDataInit().subscribe((response:any) => {
      
      this.text = response.data.text;

      this.events.publish('text', this.text);
      this.events.publish('admin', response.data.admin);
      this.apiKey = response.data.admin.ApiKey_google;
      this.injectSDK().then((res) => {
        // Obtenemos la Geolocalicacion
        if (!localStorage.getItem("address") || localStorage.getItem("address") == 'null') {
          this.server.getGeolocation();
        }
      });

      localStorage.setItem('app_text', JSON.stringify(response.data.text));
      localStorage.setItem('admin', JSON.stringify(response.data.admin));
      
      // Registramos en oneSignal
      this.subPush();
    });
  }

  private injectSDK(): Promise<any> {

    return new Promise((resolve, reject) => {

        window['mapInit'] = () => {
            resolve(true);
        }

        let script = this.renderer.createElement('script');
        script.id = 'googleMaps';

        if(this.apiKey){
            script.src = 'https://maps.googleapis.com/maps/api/js?callback=mapInit&libraries=places&key=' + this.apiKey;
        } else {
            script.src = 'https://maps.googleapis.com/maps/api/js?callback=mapInit&libraries=places';       
        }

        this.renderer.appendChild(this._document.body, script);

    });
  }
}
