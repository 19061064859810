import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { ServerService } from '../../service/server.service';
import { Stripe } from '@ionic-native/stripe/ngx';



@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.page.html',
  styleUrls: ['./form-card.page.scss'],
})
export class FormCardPage implements OnInit {
  @Input() stripe_id: any;
  @Input() total_amount: any;

  stripe_client_id:any; 
  user:any;
  text:any;
  admin:any;
  loadingCard: any;
  card_no:any;
  exp_month:any;
  exp_year:any;
  cvv:any; 
  payment_id : any;
  constructor(
    public server: ServerService,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public modalController: ModalController,
    private stripe: Stripe
  ) { 
    this.text = JSON.parse(localStorage.getItem('app_text'));
    this.admin = JSON.parse(localStorage.getItem('admin'));
    if(this.admin.stripe_client_id)
    {
      this.stripe_client_id    = this.admin.stripe_client_id; 
    }
  }

  ngOnInit() {
    console.log(this.total_amount);
  }

  ionViewWillEnter(){
    
  }

  async payWithStripe(data)
  {
    const loading = await this.loadingController.create({
      mode: 'ios'
    });
    await loading.present();

    if(data.card_number.length > 10 && data.expiration_month && data.expiration_year && data.cvv2)
    {
        loading.dismiss();
        this.stripe.setPublishableKey(this.stripe_client_id);
    
        let card = {
          number: data.card_number,
          expMonth: data.expiration_month,
          expYear: data.expiration_year,
          cvc: data.cvv2
        }
 
        this.stripe.createCardToken(card)
          .then(token => {
            console.log("Token creado");
            this.makePayment(token.id);
          })
          .catch(error => {
          this.presentToast("Por favor ingrese detalles de pago válidos",'danger');

          });
    }
    else
    {
      loading.dismiss();
      this.presentToast("Por favor ingrese detalles de pago válidos",'danger');
    }
  }

  async makePayment(token)
  {
    const loading = await this.loadingController.create({
      message: 'Enviando Informacion...',
      mode: 'ios'
    });
    await loading.present();

    this.server.makeStripePayment("?token="+token+"&amount="+this.total_amount+"&user_stripe_id="+this.stripe_id).subscribe((response:any) => {
      loading.dismiss();
      console.log(response);
      if(response.data == "done")
      {
        this.payment_id = response.id;
        if(this.payment_id)
        {
          // Cerramos el Modal
          this.modalController.dismiss(this.payment_id,'transaction_success');
        }else {
          this.modalController.dismiss();
        }
      }
      else
      {
        this.presentToast(response.error,'danger');
        // this.modalController.dismiss();
      }

    });
  }

  closeForm()
  {
    this.modalController.dismiss();
  }
   
  async presentToast(txt, color) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position : 'top',
      mode:'ios',
      color:color
    });
    toast.present();
  }
}
